<template>
  <section class="login-main">
    <div class="login-inner">
      <div class="login-l">
        <div class="login-l-inr">
          <div class="login-logo-w">
            <a href="/">
              <img src="../assets/img/redline-white-logo.svg" alt="" />
            </a>
          </div>
          <div class="login-l-middle">
            <img src="../assets/img/car-with-parts.svg" alt="" />
            <h2>{{ $t("login.inventory_system") }} </h2>
          </div>
          <div class="login-l-btm">
            <div class="phone-dtl">
              <a href="tel:+1 714 334 5505">
                <img src="../assets/img/icon-phone-call.svg" />
                <p>
                  {{ $t("login.help_call") }}
                  <b>714-334-5505</b>
                </p>
              </a>
            </div>
            <div class="social-dtl">  
              <ul>
                <li>
                  <a :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">
                    <img src="../assets/img/union.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">
                    <img src="../assets/img/fb.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">
                    <img src="../assets/img/insta.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_WHATSAPP_LINK" target="_blank">
                    <img src="../assets/img/whatsapp.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_YOUTUBE_LINK" target="_blank">
                    <img src="../assets/img/youtube.svg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="login-r">
        <div class="login-box">
          <div></div>
          <div class="login-form">
            <div class="logo-icon">
              <img src="../assets/img/ri_user-line.svg" />
            </div>
            <h2>{{ $t("login.form_heading") }}</h2>
            <b-form>
              <b-form-group id="input-group-1" :label="$t('login.email')" label-for="input-1">
                <b-form-input id="input-1" type="email" :placeholder="$t('login.email')" v-model="email"
                  @input="$v.email.$touch()" @blur="$v.email.$touch()"
                  :class="emailErrors.length ? 'error-message' : ''"></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-2" :label="$t('login.password')" label-for="input-2">
                <b-form-input id="input-2" type="password" :placeholder="$t('login.password')" v-model="password"
                  @input="$v.password.$touch()" @blur="$v.password.$touch()"
                  :class="passwordErrors.length ? 'error-message' : ''" required></b-form-input>
              </b-form-group>
              <div class="text-center mt-4">
                <b-button type="submit" class="btn custom-btn pattern-btn w-100" :disabled="$v.$invalid && $v.$anyDirty"
                  @click.prevent="doLogin"><span>{{ $t("login.loginBtn") }}</span></b-button>
              </div>
            </b-form>
          </div>
          <div class="login-footer">
            <p>Redline Network Technologies © 2024. All Rights Reserved. Terms & Conditions</p>
          </div>
          <div class="login-r-btm">
            <div class="phone-dtl">
              <a href="tel:+1 714 334 5505">
                <img src="../assets/img/icon-phone-call-green.svg" />
                <p>
                  {{ $t("login.help_call") }}
                  <b>714-334-5505</b>
                </p>
              </a>
            </div>
            <div class="social-dtl">
              <ul>
                <li>
                  <a :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">
                    <img src="../assets/img/union-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">
                    <img src="../assets/img/fb-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">
                    <img src="../assets/img/insta-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_WHATSAPP_LINK" target="_blank">
                    <img src="../assets/img/whatsapp-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_YOUTUBE_LINK" target="_blank">
                    <img src="../assets/img/youtube-green.svg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { showFailure } from "../eventbus/action.js";
export default {
  name: "Login",
  data() {
    return {
      VUE_APP_FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      VUE_APP_INSTAGRAM_LINK: process.env.VUE_APP_INSTAGRAM_LINK,
      VUE_APP_RDL_INVENTORY_LINK: process.env.VUE_APP_RDL_INVENTORY_LINK,
      VUE_APP_RDL_YOUTUBE_LINK: process.env.VUE_APP_RDL_YOUTUBE_LINK,
      VUE_APP_RDL_WHATSAPP_LINK: process.env.VUE_APP_RDL_WHATSAPP_LINK,
      email: "",
      password: "",
        errors: {
        email: null,
        password: null
      }
    };
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push(this.$t("login.validations.emailIsInvalid"));
      !this.$v.email.required &&
        errors.push(this.$t("login.validations.emailIsRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("login.validations.passwordIsRequired"));
      return errors;
    }
  },
  mounted() {
    if (this.$route.query?.auth_details_user != undefined && this.$route.query?.auth_details_user != '') {
      var user_id = this.$route.query.auth_details_user;
      this.loginFromMember(user_id);
    }
  },
  methods: {
    ...mapActions({
      login: "user/login",
      loginFromMemberPanel: "user/loginFromMemberPanel"
    }),
    async doLogin() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.$store.commit("setLoader", true);
          await this.login({
            email: this.email,
            password: this.password,
            force_login: true
          });
          this.$router.push({ name: "Dashboard" });
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showFailure(errorMessage);
          this.errors.email = message.email;
          this.errors.password = message.password;
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
    async loginFromMember(user_id) {
      try {
        this.$store.commit("setLoader", true);
        await this.loginFromMemberPanel({
          user_id: user_id
        });
        this.$router.push({ name: "Dashboard" });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object"
            ? Object.values(message)[0][0]
            : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    }
  }
};
</script>
