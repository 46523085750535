<template>
  <div class="page-sub-header">
    <div class="sub-header-left">

      <!-- responsive toggle -->
      <button @click="toggleBodyClass" class="toggle-btn">
        <img src="../assets/img/icon-bars.svg" alt="" />
      </button>
      <!-- responsive toggle -->

      <!-- back to listing button -->
      <button class="btn custom-btn pattern-btn" v-if="showBackButton" @click="backToMain()">
        <span><svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9.5L1 5.5L5 1.5" stroke="white" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          {{ $t('commonLayout.back') }}</span>
      </button>
      <!-- back to listing button -->

      <!-- <h1>{{ $route.name }}</h1> -->

      <!-- page title -->
      <h1 v-if="pageTitle == 'Dashboard'">{{ $t('sidebar.dashboard') }}</h1>
      <h1 v-if="pageTitle == 'Car list'">{{ $t('sidebar.add_vehicle_parts') }}</h1>
      <h1 v-if="pageTitle == 'Part list'">{{ $t('sidebar.add_individual_parts') }}</h1>
      <h1 v-if="pageTitle == 'Scan Barcode'">{{ $t('sidebar.barcode_scanner') }}</h1>
      <h1 v-if="pageTitle == 'Checkout'">{{ $t('sidebar.checkout') }}</h1>
      <h1 v-if="pageTitle == 'Add Vehicle'">{{ $t('carList.add_car') }}</h1>
      <h1 v-if="pageTitle == 'Edit Vehicle'">{{ $t('carList.edit_car') }}</h1>
      <h1 v-if="pageTitle == 'Part Settings'">{{ $t('carList.custom_part') }}</h1>
      <h1 v-if="pageTitle == 'Bulk Car Parts'">{{ $t('addPart.upload_bulk_part') }}</h1>
      <h1 v-if="pageTitle == 'Add Parts'">{{ $t('sidebar.add_individual_parts') }}</h1>

      <!-- page title -->

    </div>

    <div class="mr-2 ml-auto">
      <div class="language-wrapper">
        <button class="btn transparent-btn language-btn" @click="toggleDropdown">
          <img :src="lang && lang.image ? lang.image : null" class="selected-lang-image" />
        </button>
        <div v-if="showDropdown" class="language-dropdown">
          <div v-for="item in items" :key="item.lang" @click="changeLanguage(item)" :class="[
            lang.name == item.name ? 'language-option language-option-active' : 'language-option',
          ]">
            <img :src="item.image" :alt="item.name" class="language-option-image" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user" class="company-name">
      <span>
        <img src="../assets/img/icon-user.svg" alt="" />
      </span>
      <p>
        <b>{{ user.name }}</b>
        <label>{{ user.email }}</label>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import { ModelSelect } from "vue-search-select";
export default {
  components: {
    ModelSelect,
  },
  data() {
    return {
      showDropdown: false,
      lang: null,
      items: [
        {
          name: "English",
          lang: "en",
          image: require('@/assets/img/united-states.svg'),
        },
        {
          name: "Spanish",
          lang: "es",
          image: require('@/assets/img/spain.svg'),
        },
      ],
    }
  },
  async mounted() {
    var language = localStorage.getItem("lang") || 'en';
    this.lang = this.items.find((i) => i.lang === language)
  },
  methods: {
    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    },
    backToMain() {
      this.$router.back();
    },
    async changeLanguage(option) {
      this.lang = option;
      this.showDropdown = false;
      await loadLanguageAsync(option.lang);
      localStorage.setItem("lang", option.lang);
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),

    // back to main button
    showBackButton() {
      const backButtonRoutes = [
        'Part Settings',
        'Add Vehicle',
        'Edit Vehicle',
        'Bulk Car Parts',
        'Print All Barcode',
        'Add Parts'
      ];
      return backButtonRoutes.includes(this.$route.name);
    },
    pageTitle() {
      const titles = {};
      return titles[this.$route.name] || this.$route.name;
    }
  }
};
</script>