import carlistService from "@/services/carlist.service";

const state = {
  carList: [],
  carline: [],
  imageResponse: [],
  videoResponse: [],
  mediaVideoResponse: [],
  vinSearch: [],
  carDetail: [],
  years: [],
  makeList: [],
  modelList: [],
  modelWithTrim: [],
  vehicleDetail: null,
  allPrintParts: null,
  carProfileByGroupId: null,
  carProfileByIds: null,
  carProfileByBetweenDates: null,
};

const actions = {
  getCarList({ commit }, payload) {
    return carlistService.getCarList(commit, payload);
  },

  getCarDetail({ commit }, payload) {
    return carlistService.getCarDetail(commit, payload);
  },
  getAllPrintParts({ commit }, payload) {
    return carlistService.getAllPrintParts(commit, payload);
  },
  getAllPartsByGroupId({ commit }, payload) {
    return carlistService.getAllPartsByGroupId(commit, payload);
  },

  getAllPartsByIds({ commit }, payload) {
    return carlistService.getAllPartsByIds(commit, payload);
  },
  getAllPartsByBetweenDates({ commit }, payload) {
    return carlistService.getAllPartsByBetweenDates(commit, payload);
  },

  uploadImage({ commit }, payload) {
    return carlistService.uploadImage(commit, payload);
  },

  uploadVideo({ commit }, payload) {
    return carlistService.uploadVideo(commit, payload);
  },

  uploadMediaVideo({ commit }, payload) {
    return carlistService.uploadMediaVideo(commit, payload);
  },

  getYears({ commit }, payload) {
    return carlistService.getYears(commit, payload);
  },

  getMake({ commit }, payload) {
    return carlistService.getMake(commit, payload);
  },

  getCarline({ commit }, payload) {
    return carlistService.getCarline(commit, payload);
  },

  getCarlineWithoutYear({ commit }, payload) {
    return carlistService.getCarlineWithoutYear(commit, payload);
  },

  getModel({ commit }, payload) {
    return carlistService.getModel(commit, payload);
  },

  addVehicle({ commit }, payload) {
    return carlistService.addVehicle(commit, payload);
  },

  vinSearch({ commit }, data) {
    return carlistService.vinSearch(commit, data);
  },

  updateVehicle({ commit }, payload) {
    return carlistService.updateVehicle(commit, payload);
  },

  getModelWithTrim({ commit }, payload) {
    return carlistService.getModelWithTrim(commit, payload);
  },
};

const mutations = {
  setCarList(state, response) {
    state.carList = response.data;
  },

  setVehicleResponse(state, response) {
    state.vehicleDetail = response.data;
  },

  setCarDetail(state, response) {
    state.carDetail = response.data;
  },

  setAllPrintParts(state, response) {
    state.allPrintParts = response.data;
  },
  setAllPartsByGroupId(state, response) {
    state.carProfileByGroupId = response.data;
  },
  setAllPartsByIds(state, response) {
    state.carProfileByIds = response.data;
  },
  setAllPartsByBetweenDates(state, response) {
    state.carProfileByBetweenDates = response.data;
  },

  setImageResponse(state, response) {
    state.imageResponse = response.data;
  },

  setVideoResponse(state, response) {
    state.videoResponse = response.data;
  },

  setMediaVideoResponse(state, response) {
    state.mediaVideoResponse = response.data;
  },

  setYears(state, response) {
    state.years = response.data;
  },

  setMake(state, response) {
    state.makeList = response.data;
  },

  setCarline(state, response) {
    state.carline = response.data;
  },

  setModel(state, response) {
    state.modelList = response.data;
  },

  setVinSearchData(state, response) {
    state.vinSearch = response.data;
  },

  setModelWithTrim(state, response) {
    state.modelWithTrim = response.data;
  },
};

const getters = {
  getCarList(state) {
    return state.carList;
  },

  getVehicleResponse(state) {
    return state.vehicleDetail;
  },

  getCarDetail(state) {
    return state.carDetail;
  },
  getAllPrintParts(state) {
    return state.allPrintParts;
  },
  getAllPartsByGroupId(state) {
    return state.carProfileByGroupId;
  },
  getAllPartsByIds(state) {
    return state.carProfileByIds;
  },
  getAllPartsByBetweenDates(state) {
    return state.carProfileByBetweenDates;
  },

  getImageResponse(state) {
    return state.imageResponse;
  },

  getVideoResponse(state) {
    return state.videoResponse;
  },
  getMediaVideoResponse(state) {
    return state.mediaVideoResponse;
  },

  getYears(state) {
    return state.years;
  },

  getMake(state) {
    return state.makeList;
  },

  getCarline(state) {
    return state.carline;
  },

  getModel(state) {
    return state.modelList;
  },

  getVinSearchData(state) {
    return state.vinSearch;
  },

  getModelWithTrim(state) {
    return state.modelWithTrim;
  },
};

export const carlist = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
