import dashboardService from "@/services/dashboard.service";

const state = {
  dashboard: [],
  dashboardMakes: [],
  dashboardParts: [],
  dashboardMembers: []
};

const actions = {
  getDashboard({ commit }, payload) {
    return dashboardService.getDashboard(commit, payload);
  },
  getDashboardMakes({ commit }) {
    return dashboardService.getDashboardMakes(commit);
  },
  getDashboardParts({ commit }) {
    return dashboardService.getDashboardParts(commit);
  },
  getDashboardMembers({ commit }) {
    return dashboardService.getDashboardMembers(commit);
  }
};

const mutations = {
  setDashboard(state, response) {
    state.dashboard = response.data;
  },
  setDashboardMakes(state, response) {
    state.dashboardMakes = response.data;
  },
  setDashboardParts(state, response) {
    state.dashboardParts = response.data;
  },
  setDashboardMembers(state, response) {
    state.dashboardMembers = response.data;
  }
};

const getters = {
  getDashboard(state) {
    return state.dashboard;
  },
  getDashboardMakes(state) {
    return state.dashboardMakes;
  },
  getDashboardParts(state) {
    return state.dashboardParts;
  },
  getDashboardMembers(state) {
    return state.dashboardMembers;
  }
};

export const dashboard = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
