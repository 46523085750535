import http from "@/services/http.service";
import { Dashboard, DashboardMakes, DashboardParts, DashboardMembers } from "@/constants/apiUrls";

const getDashboard = async (commit, query) => {
  const response = await http.get(Dashboard, { params: { ...query } });
  commit("setDashboard", response.data);
};
const getDashboardMakes = async (commit) => {
const response = await http.get(DashboardMakes);
  commit("setDashboardMakes", response.data);
};
const getDashboardParts = async (commit) => {
const response = await http.get(DashboardParts);
  commit("setDashboardParts", response.data);
};
const getDashboardMembers = async (commit) => {
const response = await http.get(DashboardMembers);
  commit("setDashboardMembers", response.data);
};

export default {
  getDashboard,
  getDashboardMakes,
  getDashboardParts,
  getDashboardMembers,
};
