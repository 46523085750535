import EventBus, { ACTIONS } from "./index";

export const showSuccess = (message) => {
  EventBus.$emit(ACTIONS.SUCCESS, message);
};

export const showFailure = (message) => {
  EventBus.$emit(ACTIONS.FAILURE, message);
};

export const showParticipantGrid = (message) => {
  EventBus.$emit(ACTIONS.PARTICIPANT_GRID, message);
};
