import http from "@/services/http.service";
import {
  CARLIST,
  ALL_CAR_PARTS_PRINT,
  ALL_CAR_PARTS_BY_GROUP_ID,
  ALL_CAR_PARTS_BY_IDS,
  ALL_CAR_PARTS_BY_BETWEEN_DATES,
  IMAGE,
  VIDEO,
  VIDEO_UPLOAD,
  VIN_SEARCH,
  MODEL_WITH_TRIM,
  API_YEARS,
  API_MAKES,
  API_MODELS,
  API_GET_CARLINE,
  API_GET_CARLINE_WITHOUT_YEAR
} from "@/constants/apiUrls";
import tokenService from "./token.service";
import axios from "axios";

const getCarList = async (commit) => {
  const response = await http.get(CARLIST);
  commit("setCarList", response.data);
};

const getCarline = async (commit, payload) => {
  const response = await http.post(API_GET_CARLINE, payload);
  commit("setCarline", response.data);
};

const getCarlineWithoutYear = async (commit, payload) => {
  const response = await http.post(API_GET_CARLINE_WITHOUT_YEAR, payload);
  commit("setCarline", response.data);
};

const getCarDetail = async (commit, id) => {
  const response = await http.get(CARLIST + "/" + id);
  response.data.data.purchaseprice = response.data.data.purchase_price;
  response.data.data.images = response.data.data.medias.filter((item) => (item.tag != 'video'));
  // response.data.data.images = [
  //   response.data.data.medias.find((item) => item.tag == "front"),
  //   response.data.data.medias.find((item) => item.tag == "back"),
  //   response.data.data.medias.find((item) => item.tag == "right"),
  //   response.data.data.medias.find((item) => item.tag == "left"),
  // ];
  commit("setCarDetail", response.data);
};

const getAllPrintParts = async (commit, id) => {
  const response = await http.get(ALL_CAR_PARTS_PRINT + "/" + id);
  commit("setAllPrintParts", response.data);
};
const getAllPartsByGroupId = async (commit, id) => {
  const response = await http.get(ALL_CAR_PARTS_BY_GROUP_ID + "/" + id);
  commit("setAllPartsByGroupId", response.data);
};
const getAllPartsByIds = async (commit, payload) => {
  const response = await http.post(ALL_CAR_PARTS_BY_IDS, payload);
  commit("setAllPartsByIds", response.data);
};
const getAllPartsByBetweenDates = async (commit, payload) => {
  const response = await http.post(ALL_CAR_PARTS_BY_BETWEEN_DATES, payload);
  commit("setAllPartsByBetweenDates", response.data);
};

const addVehicle = async (commit, payload) => {
  payload.purchase_price = payload.purchaseprice
    .toString()
    .replace(/[^0-9/.]/g, "");
    const response = await http.post(CARLIST, { ...payload });
    commit("setVehicleResponse", response.data);
};

const updateVehicle = async (commit, payload) => {
  if(payload.purchaseprice){
    payload.purchase_price = payload.purchaseprice
    .toString()
    .replace(/[^0-9/.]/g, "");
  }
  
  const response = await http.put(CARLIST + "/" + payload.id, { ...payload });
  commit("setVehicleResponse", response.data);
};

const uploadImage = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_API_HOST + IMAGE,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setImageResponse", response);
};

const uploadVideo = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_API_HOST + VIDEO,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setVideoResponse", response);
};

const uploadMediaVideo = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_MEDIA_UPLOAD_SERVER + VIDEO_UPLOAD,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setMediaVideoResponse", response);
};

const getYears = async (commit, query) => {
  const response = await http.get(API_YEARS, { params: query });
  commit("setYears", response.data);
};

const getMake = async (commit, query) => {
  const response = await http.get(API_MAKES, { params: query });
  commit("setMake", response.data);
};

const getModel = async (commit, query) => {
  const response = await http.get(API_MODELS, { params: query });
  commit("setModel", response.data);
};

const vinSearch = async (commit, data) => {
  const response = await http.post(VIN_SEARCH, data);
  commit("setVinSearchData", response.data);
};

const getModelWithTrim = async (commit, query) => {
  const response = await http.get(MODEL_WITH_TRIM, { params: query });
  commit("setModelWithTrim", response.data);
};

export default {
  getCarList,
  getCarline,
  getCarlineWithoutYear,
  uploadImage,
  uploadVideo,
  uploadMediaVideo,
  getYears,
  getMake,
  getModel,
  addVehicle,
  vinSearch,
  getCarDetail,
  getAllPrintParts,
  getAllPartsByGroupId,
  getAllPartsByIds,
  getAllPartsByBetweenDates,
  updateVehicle,
  getModelWithTrim,
};
