import tokenService from "@/services/token.service";
import authService from "@/services/auth.service";

const state = {
  loggedIn: !!tokenService.getAccessToken() || false,
  accessToken: tokenService.getAccessToken(),
  user: tokenService.getUserData()
};

const getters = {
  isLoggedIn(state) {
    return state.loggedIn;
  },
  getUser(state) {
    return state.user;
  }
};

const actions = {
  login({ commit }, payload) {
    return authService.login(commit, payload);
  },
  loginFromMemberPanel({ commit }, payload) {
    return authService.loginFromMemberPanel(commit, payload);
  },
  logout({ commit }) {
    return authService.logout(commit);
  },
  getUser({ commit }) {
    return authService.getUser(commit);
  }
};

const mutations = {
  loginSuccess(state, response) {
    state.loggedIn = true;
    state.accessToken = response.data.token;
    tokenService.setAccessToken(response.data.token);
  },
  logout(state) {
    state.loggedIn = false;
    state.user = {};
    state.accessToken = null;
    tokenService.clear();
  },
  setUser(state, response) {
    state.user = response.data;
    state.services = state.user.services;
    tokenService.setUserData(response.data);
  }
};

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
