import http from "@/services/http.service";
import { LOGIN, LOGIN_FROM_MEMBER_PANEL, PROFILE, LOGOUT } from "@/constants/apiUrls";

const login = async (commit, payload) => {
  const response = await http.post(LOGIN, payload);
  commit("loginSuccess", response.data);
  await profile(commit);
};

const loginFromMemberPanel = async (commit, payload) => {
  const response = await http.post(LOGIN_FROM_MEMBER_PANEL, payload);
  commit("loginSuccess", response.data);
  await profile(commit);
};

const logout = async (commit) => {
  await http.post(LOGOUT);
  commit("logout");
};

const profile = async (commit) => {
  const response = await http.get(PROFILE);
  commit("setUser", response.data);
};

export default {
  login,
  loginFromMemberPanel,
  logout,
  profile,
};
