export const LOGOUT = "inventory/auth/logout";
export const LOGIN = "inventory/auth/login";
export const LOGIN_FROM_MEMBER_PANEL = "inventory/auth/login-from-member";
export const PROFILE = "inventory/auth/user";
export const CARLIST = "inventory/auction-car-profiles";
export const ALL_CAR_PARTS_PRINT = "inventory/all-car-parts-print";
export const ALL_CAR_PARTS_BY_GROUP_ID = "inventory/all-car-parts-by-group-id";
export const ALL_CAR_PARTS_BY_IDS = "inventory/all-car-parts-by-ids";
export const ALL_CAR_PARTS_BY_BETWEEN_DATES = "inventory/all-car-parts-by-between-dates";
export const IMAGE = "inventory/upload-medias";
export const IMAGE_UPLOAD = "redline/upload-image";
export const VIDEO_UPLOAD = "redline/upload-video";
export const VIDEO = "inventory/upload-video-medias";
export const VIN_SEARCH = "inventory/vin-search-unique-by-user";
export const PARTLIST = "inventory/car-parts";
export const PARTLIST_DELETE_BULK = "inventory/car-parts/bulk-delete";
export const CHECKOUT_PARTLIST = "inventory/checkout/car-parts";
export const CHECKOUT_ADD_REMOVE = "inventory/checkout/add-remove";
export const CAR_PART_DETAILS = "inventory/car-part"; //get details
export const SELECTED_PARTS = "inventory/selected-car-parts";
export const SELECTED_PARTS_UPDATE = "inventory/selected-car-parts-update";
export const GET_PARTS = "inventory/inventory-parts-settings";
export const DIRECT_PARTS = "inventory/direct-car-profiles";
export const PARTNAMES = "inventory/part-names";
export const MAKEOPTION = "inventory/makes";
export const MODELOPTION = "inventory/models";
export const YEAROPTION = "inventory/years";
export const Dashboard = "inventory/dashboard";
export const DashboardMakes = "inventory/dashboard-makes";
export const DashboardParts = "inventory/dashboard-parts";
export const DashboardMembers = "inventory/dashboard-members";
export const TRIMOPTION = "inventory/trims";
export const MODEL_WITH_TRIM = "inventory/models-with-trims";
export const API_YEARS = "inventory/years";
export const API_MAKES = "api/makes";
export const API_MODELS = "api/models";
export const API_GET_CARLINE = "api/interchange/carline";
export const API_GET_CARLINE_WITHOUT_YEAR = "api/interchange/carlineWithoutYear";
export const GET_INTERCHANGE = "inventory/interchange";
export const PART_LIST = "inventory/parts";
export const AUCTION_PARTS = "inventory/auction-car-parts";
export const OLD_INVENTORY = "inventory/old-car-profiles";
export const THIRD_PARTY_PART_DELETE = "inventory/old-car-profiles";
export const GET_P_TYPE = "inventory/pType";

//inventory interchange
export const INTERCHANGE_YEARS = "inventory/interchange/years-hollander";
export const INTERCHANGE_MAKES = "inventory/interchange/makes-hollander";
export const INTERCHANGE_MODELS = "inventory/interchange/models-hollander";
