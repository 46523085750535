import http from "@/services/http.service";
import {
  INTERCHANGE_YEARS,
  INTERCHANGE_MAKES,
  INTERCHANGE_MODELS,
} from "@/constants/apiUrls";

const getYears = async (commit, query) => {
  const response = await http.get(INTERCHANGE_YEARS, { params: query });
  commit("setYears", response.data);
};

const getMake = async (commit, query) => {
  const response = await http.get(INTERCHANGE_MAKES, { params: query });
  commit("setMake", response.data);
};

const getModel = async (commit, query) => {
  const response = await http.get(INTERCHANGE_MODELS, { params: query });
  commit("setModel", response.data);
};

export default {
  getYears,
  getMake,
  getModel,
};
